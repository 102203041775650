import Vue from 'vue'
import BootstrapVue from 'bootstrap-vue';
import App from './App.vue'

import AnimateOnVisible from "./components/AnimateOnVisible.vue"

Vue.use(BootstrapVue)

import VueTimeline from "@growthbunker/vuetimeline";

Vue.use(VueTimeline);

import { library } from '@fortawesome/fontawesome-svg-core'
import { faCopyright} from '@fortawesome/free-solid-svg-icons'
import { faFacebookSquare, faLinkedin, faGithubSquare, faGitlab, faTwitterSquare, faRedditSquare } from '@fortawesome/free-brands-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import AOS from 'aos'
import 'aos/dist/aos.css'

import VueNumber from 'vue-number-animation'

Vue.use(VueNumber)

library.add(faCopyright, faFacebookSquare, faLinkedin, faGithubSquare, faGitlab, faTwitterSquare, faRedditSquare)

Vue.component('font-awesome-icon', FontAwesomeIcon)
Vue.component('AnimateOnVisible', AnimateOnVisible)
Vue.config.productionTip = false

new Vue({
  render: h => h(App),
  mounted() {
    AOS.init()
  },
}).$mount('#app')

import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'
import './styles/global.scss'
